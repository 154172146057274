import React, { useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import { Heading2 } from "@styles/ui"
import PrimaryCta from "@components/PrimaryCta"
import VideoGallery from "@components/videoGallery"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Anchor from "@components/Anchor"
import PatientVoice from "@components/PatientVoice"

import standupChampionsLogo from "@images/logos/logo-sucs.svg"
import brookeHeading from "@images/standup-champions/brooke-title.svg"
import oscarHeading from "@images/standup-champions/oscar-title.svg"
import graceHeading from "@images/standup-champions/grace-title.svg"
import ginaHeading from "@images/standup-champions/gina-title.svg"
import jakeHeading from "@images/standup-champions/jake-title.svg"

// Video gallery gifs
import gifGraceThumb1 from "@images/standup-champions/grace-video-1.gif"
import gifGraceThumb2 from "@images/standup-champions/grace-video-2.gif"
import gifGraceThumb3 from "@images/standup-champions/grace-video-3.gif"
import gifGraceThumb4 from "@images/standup-champions/grace-video-4.gif"
import gifGraceThumb5 from "@images/standup-champions/grace-video-5.gif"
import gifBrookeThumb1 from "@images/standup-champions/brooke-video-1.gif"
import gifBrookeThumb2 from "@images/standup-champions/brooke-video-2.gif"
import gifBrookeThumb3 from "@images/standup-champions/brooke-video-3.gif"
import gifBrookeThumb4 from "@images/standup-champions/brooke-video-4.gif"
import gifOscarThumb1 from "@images/standup-champions/oscar-video-1.gif"
import gifOscarThumb2 from "@images/standup-champions/oscar-video-2.gif"
import gifOscarThumb3 from "@images/standup-champions/oscar-video-3.gif"
import videoGifThumb1 from "@images/standup-champions/gina-clip-1.mp4"
import videoGifThumb2 from "@images/standup-champions/gina-clip-2.mp4"
import videoGifThumb3 from "@images/standup-champions/gina-clip-3.mp4"

const PatientStoriesPage = ({ location, data }) => {
  const [currentGallery, setCurrentGallery] = useState(null)

  const anchorLinks = [
    { label: "Grace", link: "#grace" },
    { label: "Jake", link: "#jake" },
    { label: "Gina", link: "#gina" },
    { label: "Brooke", link: "#brooke" },
    { label: "Oscar", link: "#oscar" },
  ]

  const videoGalleryGrace = [
    {
      wistiaId: "eygrtzfz01",
      title: <>Grace discusses her&nbsp;diagnosis</>,
      description:
        "Watch Grace discuss when she was diagnosed with narcolepsy and how she experienced EDS and cataplexy.",
      thumbnail: data.vidGrace1,
      thumbnailSmall: data.vidGrace1Small,
      gifSrc: gifGraceThumb1,
      alt: "Watch the video to hear about Grace's narcolepsy diagnosis",
      id: 0,
    },
    {
      wistiaId: "jh9qkp4xby",
      title: <>Grace talks with her&nbsp;doctor</>,
      description:
        "Hear Grace talk about discussing oxybate treatments with her doctor to help address her symptoms of EDS and cataplexy.",
      thumbnail: data.vidGrace2,
      thumbnailSmall: data.vidGrace2Small,
      gifSrc: gifGraceThumb2,
      alt: "Watch the video to hear Grace discuss treatments with her doctor",
      id: 1,
    },
    {
      wistiaId: "c0n9xke4im",
      title: <>Grace&prime;s experience taking&nbsp;XYWAV</>,
      description:
        "Hear Grace reveal her experience taking XYWAV for 2 key narcolepsy symptoms.",
      thumbnail: data.vidGrace3,
      thumbnailSmall: data.vidGrace3Small,
      gifSrc: gifGraceThumb3,
      alt: "Watch video to hear Grace's experience taking XYWAV",
      id: 2,
    },
    {
      wistiaId: "nt494qfoch",
      title: <>Grace&prime;s nighttime routine with XYWAV</>,
      description:
        "Grace shares her nighttime routine and how she builds it around taking XYWAV nightly.",
      thumbnail: data.vidGrace4,
      thumbnailSmall: data.vidGrace4Small,
      gifSrc: gifGraceThumb4,
      alt: "Watch video as Grace shares her nighttime routine with XYWAV®",
      id: 3,
    },
    {
      wistiaId: "hz03k7d26o",
      title: (
        <>
          Grace&prime;s experience with&nbsp;JazzCares<sup>&reg;</sup>
        </>
      ),
      description:
        "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV.",
      thumbnail: data.vidGrace5,
      thumbnailSmall: data.vidGrace5Small,
      gifSrc: gifGraceThumb5,
      alt: "Watch video about Grace's experience with JazzCares®",
      id: 4,
    },
  ]

  const videoGalleryJake = [
    {
      wistiaId: "oacrklfurr",
      title: <>Jake&apos;s road to diagnosis</>,
      description:
        "Hear from Jake about his experiences with cataplexy and excessive daytime sleepiness before his narcolepsy diagnosis.",
      thumbnail: data.vidJake1,
      thumbnailSmall: data.vidJake1Small,
      // videoGifSrc: videoGifThumb1,
      alt: "Watch the video to hear about Jake's experience with his excessive daytime sleepiness and cataplexy symptoms",
      id: 0,
    },
    {
      wistiaId: "4uv3v9elby",
      title: <>Jake discusses treatment options with his doctor</>,
      description:
        "Learn why XYWAV and its sodium content stood out to Jake when he was evaluating his treatment options.",
      thumbnail: data.vidJake2,
      thumbnailSmall: data.vidJake2Small,
      // videoGifSrc: videoGifThumb2,
      alt: "Watch the video to hear about Jake's treatment options",
      id: 1,
    },
    {
      wistiaId: "th0lvbpohr",
      title: <>Jake&apos;s treatment with XYWAV</>,
      description:
        "Hear Jake talk about his experience taking XYWAV and how having a nighttime routine helps him stay on track of his treatment.",
      thumbnail: data.vidJake3,
      thumbnailSmall: data.vidJake3Small,
      // videoGifSrc: videoGifThumb3,
      alt: "Watch the video to hear about Jake's nighttime routine and his experience with taking XYWAV®",
      id: 2,
    },
    {
      wistiaId: "dtqolwbhy4",
      title: <>Jake&apos;s experience with JazzCares</>,
      description:
        "Jake shares how JazzCares helped address his concerns about cost and dose adjustment when getting started on XYWAV.",
      thumbnail: data.vidJake4,
      thumbnailSmall: data.vidJake4Small,
      // videoGifSrc: videoGifThumb3,
      alt: "Watch video about Jake's experience with JazzCares®",
      id: 3,
    },
  ]

  const videoGalleryGina = [
    {
      wistiaId: "wolmgpnmb6",
      title: <>Gina shares her journey to diagnosis</>,
      description:
        "Watch Gina talk about the time when she was finally able to put a name to her symptoms of cataplexy and excessive daytime sleepiness.",
      thumbnail: data.vidGina1,
      thumbnailSmall: data.vidGina1Small,
      videoGifSrc: videoGifThumb1,
      alt: "Watch a video on Gina's cataplexy and excessive daytime sleepiness symptoms",
      id: 0,
    },
    {
      wistiaId: "namp2arog6",
      title: "Gina discusses sodium intake with her doctor",
      description:
        "Find out why Gina talked with her doctor about the sodium content of her medication.",
      thumbnail: data.vidGina2,
      thumbnailSmall: data.vidGina2Small,
      videoGifSrc: videoGifThumb2,
      alt: "Watch a video on Gina's sodium intake and how that can affect treatment choices",
      id: 1,
    },
    {
      wistiaId: "krylp6n944",
      title: <>Gina&prime;s treatment with&nbsp;XYWAV</>,
      description:
        "Hear how Gina and her doctor found her individualized dose of XYWAV and the importance of her nighttime routine.",
      thumbnail: data.vidGina3,
      thumbnailSmall: data.vidGina3Small,
      videoGifSrc: videoGifThumb3,
      alt: "Watch a video on determining the best dosage for Gina",
      id: 2,
    },
  ]

  const videoGalleryBrooke = [
    {
      wistiaId: "k3uye4zoh3",
      title: <>Brooke&prime;s journey to&nbsp;diagnosis</>,
      description:
        "Watch Brooke talk about her journey to diagnosis and starting treatment with XYWAV.",
      thumbnail: data.vidBrooke1,
      thumbnailSmall: data.vidBrooke1Small,
      gifSrc: gifBrookeThumb1,
      alt: "Watch the video to hear about Brooke's diagnosis and starting her treatment with XYWAV®",
      id: 0,
      metadata: {
        name: "Grace's Experience With JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=hz03k7d26o",
        duration: "PT6M21S",
      },
    },
    {
      wistiaId: "ch2cf95fak",
      title: <>Brooke discusses sodium content</>,
      description:
        "Listen to Brooke discuss why having lower sodium content is important to her.",
      thumbnail: data.vidBrooke2,
      thumbnailSmall: data.vidBrooke2Small,
      gifSrc: gifBrookeThumb2,
      alt: "Watch video to hear Brooke discuss why lower sodium content is important to her",
      id: 1,
    },
    {
      wistiaId: "3h8myw6qnd",
      title: <>Brooke&prime;s nighttime routine with&nbsp;XYWAV</>,
      description:
        "Hear from Brooke about how XYWAV has become an important part of her nighttime routine.",
      thumbnail: data.vidBrooke3,
      thumbnailSmall: data.vidBrooke3Small,
      gifSrc: gifBrookeThumb3,
      alt: "Watch the video to hear about Brooke's nighttime routine with XYWAV®",
      id: 2,
    },
    {
      wistiaId: "elmp5009xf",
      title: "Brooke discusses JazzCares",
      description:
        "Find out how Brooke gets support every step of her treatment journey with JazzCares.",
      thumbnail: data.vidBrooke4,
      thumbnailSmall: data.vidBrooke4Small,
      gifSrc: gifBrookeThumb4,
      alt: "Watch the video to hear about Brooke's experience with JazzCares®",
      id: 3,
    },
  ]

  const videoGalleryOscar = [
    {
      wistiaId: "y7bntnqdi2",
      title: (
        <>
          Oscar&prime;s journey to diagnosis and
          <br tw="hidden lg:block" /> treatment
        </>
      ),
      description:
        "Hear from Oscar about how he was first diagnosed with narcolepsy and how he finally found treatment with XYWAV after taking wake-promoting agents.",
      thumbnail: data.vidOscar1,
      thumbnailSmall: data.vidOscar1Small,
      gifSrc: gifOscarThumb1,
      alt: "Watch the video on Oscar's journey to diagnosis and treatment",
      id: 0,
    },
    {
      wistiaId: "ewodm1jozj",
      title: <>How Oscar prioritizes his&nbsp;health</>,
      description:
        "Listen to Oscar discuss how he prioritizes a healthy lifestyle.",
      thumbnail: data.vidOscar2,
      thumbnailSmall: data.vidOscar2Small,
      gifSrc: gifOscarThumb2,
      alt: "Watch video to learn how Oscar prioritizes his health",
      id: 1,
    },
    {
      wistiaId: "d5lk6iomzx",
      title: <>Oscar&prime;s evening routine with&nbsp;XYWAV</>,
      description:
        "Watch Oscar talk about his evening routine and how he takes his XYWAV doses nightly.",
      thumbnail: data.vidOscar3,
      thumbnailSmall: data.vidOscar3Small,
      gifSrc: gifOscarThumb3,
      alt: "Watch video to hear about Oscar's evening routine with XYWAV®",
      id: 2,
    },
  ]

  const videoMetaData = [
    {
      metadata: {
        name: "Grace Discusses Her Diagnosis | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Grace discuss when she was diagnosed. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=eygrtzfz01",
        duration: "PT6M30S",
      },
    },
    {
      metadata: {
        name: "Grace Talks With Her Doctor | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear Grace talk about discussing oxybate treatments with her doctor to help address her symptoms of EDS and cataplexy.",
        thumbnailUrl: data.vidGrace2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=jh9qkp4xby",
        duration: "PT7M3S",
      },
    },
    {
      metadata: {
        name: "Grace's Experience Taking XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Grace as she reveals her XYWAV experience. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=c0n9xke4im",
        duration: "PT 6M8S",
      },
    },
    {
      metadata: {
        name: "Grace's Nighttime Routine With XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Find out what Grace has to say about her nighttime routine and how she builds it around XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=nt494qfoch",
        duration: "PT6M21S",
      },
    },
    {
      metadata: {
        name: "Grace's Experience With JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace5.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=hz03k7d26o",
        duration: "PT7M2S",
      },
    },
    {
      metadata: {
        name: "Brooke's Journey to Diagnosis | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Brooke talk about her journey to diagnosis and starting treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=k3uye4zoh3",
        duration: "PT7M31S",
      },
    },
    {
      metadata: {
        name: "Brooke Discusses Sodium Content | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Brooke discuss why having lower sodium content is important to her. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=ch2cf95fak",
        duration: "PT6M15S",
      },
    },
    {
      metadata: {
        name: "Brooke's Nighttime Routine With XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear from Brooke about how XYWAV has become an important part of her nighttime routine. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=3h8myw6qnd",
        duration: "PT7M21S",
      },
    },
    {
      metadata: {
        name: "Brooke Discusses JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Find out how Brooke gets support every step of her treatment journey with JazzCares. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=elmp5009xf",
        duration: "PT7M29S",
      },
    },
    {
      metadata: {
        name: "Narcolepsy Diagnosis Journey | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear from Oscar about how he was first diagnosed and how he finally found treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=y7bntnqdi2",
        duration: "PT6M30S",
      },
    },
    {
      metadata: {
        name: "Morning and Lunchtime Routine With Narcolepsy | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Oscar discuss his morning and lunchtime routines and how he prioritizes his health. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=ewodm1jozj",
        duration: "PT6M32S",
      },
    },
    {
      metadata: {
        name: "Evening Routine With Narcolepsy | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Oscar talk about his evening routine, how he adjusts it when necessary, and how he takes his XYWAV doses. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=d5lk6iomzx",
        duration: "PT7M59S",
      },
    },
  ]

  const heroImage = withArtDirection(getImage(data.heroMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(data.hero),
    },
  ])

  return (
    <Layout
      type="narcolepsy"
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200245 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="Narcolepsy Patient Stories and Testimonials | XYWAV®"
        description="Hear from patients living with narcolepsy. Learn about their experiences with XYWAV® (calcium, magnesium, potassium, and sodium oxybates) as they navigate their symptoms, diagnosis, and treatment journeys. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
        videoMetadata={videoMetaData}
      />

      <section tw="relative overflow-hidden md:(grid min-h-[300px])">
        <div tw="relative md:(absolute top-0 right-0 grid grid-cols-12 justify-items-end overflow-hidden)">
          <div tw="relative overflow-hidden md:(col-span-8 col-start-5)">
            <GatsbyImage
              tw="md:(h-[500px] -top-16) md-lg:(w-[150%] [img]:absolute left-[-200px]) "
              image={heroImage}
              loading="eager"
              alt="A woman doing yoga on the beach"
              imgStyle={{
                width: "100%",
                height: "100%",
              }}
            />
            <div tw="mobile-narc-home-hero-gradient absolute left-0 bottom-0 w-screen h-[200px] block md:(narc-home-hero-gradient top-0 h-[300px] w-[250px])" />
          </div>
        </div>
        <Container>
          <div tw="-mt-[66px] relative pt-5 pb-4 md:(mt-0 py-0 grid h-full grid-cols-12 overflow-hidden)">
            <div tw="col-span-8 md:(flex items-center justify-start)">
              <div tw="md:(mt-12 max-w-[700px])">
                <h1 tw="pl-0.5 text-[12px] uppercase font-avenir font-black tracking-[1px] md:(font-bold text-4xl pl-1 mb-3)">
                  Real patients, <br tw="md:(hidden)" />
                  Real stories, <br tw="md:(hidden)" />
                  Real inspiration
                </h1>
                <img
                  src={standupChampionsLogo}
                  alt=""
                  tw="mt-[15px] w-[307px] md:(w-[645px] ml-[110px])"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Main content */}
      <Section tw="md:(pt-14 pb-[25px])">
        <Container>
          <div tw="md:(grid grid-cols-12 gap-x-9 items-center)">
            <h2
              css={[
                Heading2,
                tw`font-avenir font-black text-[20px] leading-normal md:(col-span-6 text-[22px] pr-2 -mt-8)`,
                tw`before:(block w-[80px] mb-7.5 h-1.5 rounded-md bg-small-before-gradient) xl:(before:mb-5)`,
              ]}
            >
              Standup Champions are people who take XYWAV and want to share
              their successes and challenges to inspire others with their
              narcolepsy journey
            </h2>

            <p tw="mt-[15px] text-base md:(mt-0 col-span-6)">
              Whether they&apos;re sharing a full day in their lives or offering
              a few words of encouragement, our Standup Champions want to help
              other people who have cataplexy and/or excessive daytime
              sleepiness (EDS) in narcolepsy with the knowledge they&apos;ve
              gained through their diagnosis and treatment journeys.
            </p>
          </div>
          <hr tw="my-[15px] md:my-[25px]" />
          <div tw="flex flex-wrap gap-y-4 gap-x-5 items-start justify-center w-[285px] mx-auto md:(w-full flex-row flex-nowrap justify-between px-8)">
            <p tw="font-cocon font-black text-[25px] leading-[120%] w-full text-center mb-2 md:(w-auto mb-0)">
              Hear From:
            </p>
            {anchorLinks.map((link, index) => {
              return (
                <div key={index} tw="text-center md:max-w-[200px]">
                  <Anchor
                    link={link.link}
                    addedStyles={tw`font-extrabold text-[22px] leading-[125%] mb-1.5`}
                  >
                    {link.label}
                  </Anchor>
                </div>
              )
            })}
          </div>
        </Container>
      </Section>

      <section tw="lilac-gradient-vertical py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-11 lg:pb-[50px]">
            <img
              src={graceHeading}
              alt=""
              tw="h-[75px] mx-auto mb-[15px] md:(h-[120px] mb-[35px])"
            />
            <p tw="text-center text-base">
              See how Grace, a student in her 20s, navigates her journey with
              narcolepsy type 1
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryGrace}
            location={location}
            sectionId="grace"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="3"
            hideVideos
          />
        </Container>
      </section>

      <section tw="lilac-gradient-vertical py-[1.9rem] md:py-[3.1rem]">
        <Container>
          <div tw="pb-11 lg:pb-[50px]">
            <img
              src={jakeHeading}
              alt=""
              tw="h-[80px] mx-auto mb-[15px] md:(h-[140px] mb-[35px])"
            />
            <p tw="text-center text-base">
              Join Jake, an oxybate first&#8209;timer, as he shares his journey
              living with narcolepsy
            </p>
          </div>
          <div tw="pb-[1.875rem] lg:pb-[50px]">
            <VideoGallery
              theme={"suc"}
              videos={videoGalleryJake}
              location={location}
              sectionId="jake"
              currentGallery={currentGallery}
              setCurrentGallery={setCurrentGallery}
              galleryId="3"
              hideVideos
            />
          </div>
          <PatientVoice
            imageRight
            quote={
              <>
                Choosing a lower sodium option was something I could do to lower
                my risk of cardiovascular problems due to excessive sodium.
                That&apos;s why XYWAV, the first and only low-sodium oxybate,
                stood out to me.&#8221;
              </>
            }
            patient="Jake"
            diagnosis="Diagnosed with narcolepsy type 1."
            imageData={data}
            id="jake-doctor-quote"
            footnote="Compensated for his time. Results may vary."
          />
        </Container>
      </section>

      <section tw="lilac-gradient-vertical py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={ginaHeading}
              alt=""
              tw="h-[75px] mx-auto mb-[15px] md:(h-[120px] mb-[35px])"
            />
            <p tw="text-center text-base">
              Hear from Gina about how she navigates living with narcolepsy and
              her experience taking XYWAV
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryGina}
            location={location}
            sectionId="gina"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="2"
            hideVideos
          />
        </Container>
      </section>

      <section tw="lilac-gradient-vertical py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={brookeHeading}
              alt=""
              tw="h-[75px] mx-auto mb-[15px] md:(h-[120px] mb-[35px])"
            />
            <p tw="text-center text-base">
              Meet Brooke and find out what life with narcolepsy is like for her
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryBrooke}
            location={location}
            sectionId="brooke"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="3"
            hideVideos
          />
        </Container>
      </section>

      <section tw="lilac-gradient-vertical py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={oscarHeading}
              alt=""
              tw="h-[75px] mx-auto mb-[15px] md:(h-[120px] mb-[35px])"
            />
            <p tw="text-center text-base">
              Learn how Oscar, a health and fitness enthusiast, considers his
              heart health when managing narcolepsy
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryOscar}
            location={location}
            sectionId="oscar"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="4"
            hideVideos
          />
        </Container>
      </section>

      <section tw="py-[30px] md:py-[50px] all-colours-gradient">
        <Container>
          <h2 css={[Heading2, tw`mb-6 mx-3 text-center text-white`]}>
            Interested in sharing your journey with others who are struggling
            with narcolepsy?
          </h2>
          <div tw="mx-auto my-[15px] flex justify-center mb-0">
            <PrimaryCta
              url="/become-a-standup-champion/"
              width={tw`max-w-[230px] md:max-w-[230px]`}
            >
              Submit your story
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PatientStoriesPage

export const query = graphql`
  query {
    hero: file(
      relativePath: { eq: "standup-champions/woman-yoga-on-beach.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 912, placeholder: NONE)
      }
    }
    heroMobile: file(
      relativePath: { eq: "standup-champions/woman-yoga-on-beach-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "standup-champions/jake-headshot-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "standup-champions/jake-headshot-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    vidGrace1: file(
      relativePath: { eq: "video-thumbnails/grace-diagnosis-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace1Small: file(
      relativePath: { eq: "video-thumbnails/grace-diagnosis-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace2: file(
      relativePath: {
        eq: "video-thumbnails/grace-doctor-discussion-thumbnail.png"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace2Small: file(
      relativePath: {
        eq: "video-thumbnails/grace-doctor-discussion-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace3: file(
      relativePath: { eq: "video-thumbnails/grace-taking-xywav-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace3Small: file(
      relativePath: { eq: "video-thumbnails/grace-taking-xywav-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace4: file(
      relativePath: { eq: "video-thumbnails/grace-nighttime-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace4Small: file(
      relativePath: { eq: "video-thumbnails/grace-nighttime-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace5: file(
      relativePath: { eq: "video-thumbnails/grace-jazzcares-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace5Small: file(
      relativePath: { eq: "video-thumbnails/grace-jazzcares-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJake1: file(
      relativePath: { eq: "video-thumbnails/jake-diagnosis-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJake1Small: file(
      relativePath: { eq: "video-thumbnails/jake-diagnosis-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJake2: file(
      relativePath: { eq: "video-thumbnails/jake-treatment-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJake2Small: file(
      relativePath: { eq: "video-thumbnails/jake-treatment-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJake3: file(
      relativePath: { eq: "video-thumbnails/jake-nighttime-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJake3Small: file(
      relativePath: { eq: "video-thumbnails/jake-nighttime-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJake4: file(
      relativePath: { eq: "video-thumbnails/jake-jazzcares-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJake4Small: file(
      relativePath: { eq: "video-thumbnails/jake-jazzcares-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke1: file(
      relativePath: { eq: "video-thumbnails/brooke-diagnosis-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke1Small: file(
      relativePath: { eq: "video-thumbnails/brooke-diagnosis-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke2: file(
      relativePath: {
        eq: "video-thumbnails/brooke-sodium-content-thumbnail.png"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke2Small: file(
      relativePath: {
        eq: "video-thumbnails/brooke-sodium-content-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke3: file(
      relativePath: { eq: "video-thumbnails/brooke-nighttime-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke3Small: file(
      relativePath: { eq: "video-thumbnails/brooke-nighttime-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke4: file(
      relativePath: { eq: "video-thumbnails/brooke-jazzcares-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke4Small: file(
      relativePath: { eq: "video-thumbnails/brooke-jazzcares-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar1: file(
      relativePath: { eq: "video-thumbnails/oscar-diagnosis-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar1Small: file(
      relativePath: { eq: "video-thumbnails/oscar-diagnosis-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar2: file(
      relativePath: {
        eq: "video-thumbnails/oscar-sodium-content-thumbnail.png"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar2Small: file(
      relativePath: {
        eq: "video-thumbnails/oscar-sodium-content-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar3: file(
      relativePath: { eq: "video-thumbnails/oscar-nighttime-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar3Small: file(
      relativePath: { eq: "video-thumbnails/oscar-nighttime-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    patientVoiceBrooke: file(
      relativePath: { eq: "standup-champions/brooke-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 425, placeholder: NONE)
      }
    }
    patientVoiceBrookeMobile: file(
      relativePath: { eq: "standup-champions/brooke-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    vidGina1: file(
      relativePath: {
        eq: "video-thumbnails/gina-understanding-narcolepsy-symptoms.jpg"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina1Small: file(
      relativePath: {
        eq: "video-thumbnails/gina-understanding-narcolepsy-symptoms.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGina2: file(
      relativePath: { eq: "video-thumbnails/gina-sodium-intake.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina2Small: file(
      relativePath: { eq: "video-thumbnails/gina-sodium-intake.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGina3: file(
      relativePath: { eq: "video-thumbnails/gina-dosing-titration.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina3Small: file(
      relativePath: { eq: "video-thumbnails/gina-dosing-titration.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
  }
`
